export class Appconstants {
    static readonly TRACKING_PACKAGE: string = "Tracking Your Package";
    static readonly TRACK_BUTTON: string = "SEARCH";
    static readonly TRACK_ANOTHER_SHIPMENT: string = "TRACK ANOTHER PACKAGE";
    static readonly DATE_HEADER: string = "DATE";
    static readonly TIME_HEADER: string = "TIME";
    static readonly STATUS_HEADER: string = "STATUS";
    static readonly LOCATION_HEADER: string = "LOCATION";
    static readonly DIRECTION_REQUEST_FAILED: string =
      "Directions request failed due to";
    static readonly TRACKING_NUMBER_FORM_FIELD: string =
      "Enter your tracking number";
    static readonly COPYRIGHT: string = "Copyright";
    static readonly BETTER_TRUCKS_WEBTRACKING: string =
      "Better Trucks WebTracking";
    static readonly RIGHTS_RESERVERD: string = "All rights reserved.";
    static readonly VERSION_TEXT: string = "Version";
    static readonly VERSION_NUMBER: string = "1.0.163";
    static readonly IN_TRANSIT_TEXT: string = "In Transit";
    static readonly RETURNED_TO_STATION_TEXT: string = "Return To Station";
    static readonly SHIPMENT_CREATED_TEXT: string = "Shipment Created";
  }
  