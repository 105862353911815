import { FC, useState } from "react";
import { Carousel, Modal, Button } from "react-bootstrap";
import { Image } from "shared/models/tracking-model";
import "./ImageGallery.scss";

interface Props {
  toggle: () => void;
  images: Image[];
  activeIndex: number;
}

export const ImageGallery: FC<Props> = ({ images, toggle, activeIndex }) => {
  const [scrollIndex, setScrollIndex] = useState(activeIndex);

  const handleSelect = (selectedIndex: number) => {
    setScrollIndex(selectedIndex);
  };
  const onClose = () => {
    toggle();
  };

  return (
    <Modal
      show={images.length > 0 ? true : false}
      onHide={onClose}
      animation={false}
      centered
      className="POD-image-model"
    >
      <Modal.Header>
        <Carousel
          activeIndex={scrollIndex}
          onSelect={handleSelect}
          indicators={false}
        >
          {images?.map((image: Image, index: number) => (
            <Carousel.Item key={index}>
              <img
                src={image.imageUrl || ""}
                className="d-block w-100"
                alt={`Image ${index + 1}`}
              />
            </Carousel.Item>
          ))}
        </Carousel>
        <Carousel.Caption>{`${scrollIndex + 1} of ${
          images.length
        }`}</Carousel.Caption>
        <Button className="btn-close" aria-label="Close" onClick={onClose} />
      </Modal.Header>
    </Modal>
  );
};
