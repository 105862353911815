import React, { useEffect, useState } from "react";
import { Col, Container, Row, Table } from "react-bootstrap";
import { Link, Navigate, useLocation } from "react-router-dom";
import GoogleMapReact from "google-map-react";
import DOMPurify from "dompurify";
import notFoundImg from "../../assets/Images/not-found-img.svg";
import trackingDelivered from "../../assets/Images/tracking-delivered.svg";
import trackingConfirmed from "../../assets/Images/tracking-confirmed.svg";
import trackingOutForDelivery from "../../assets/Images/tracking-outfordelivery.svg";
import trackingTransit from "../../assets/Images/tracking-transit.svg";
import trackingDeliveredMobile from "../../assets/Images/tracking-delivered-mobile.svg";
import trackingConfirmedMobile from "../../assets/Images/tracking-confirmed-mobile.svg";
import trackingOutForDeliveryMobile from "../../assets/Images/tracking-outfordelivery-mobile.svg";
import trackingTransitMobile from "../../assets/Images/tracking-transit-mobile.svg";
import { Header, Footer, ImageGallery } from "components";
import config from "config";
import "./index.scss";
import $ from "jquery";
import { Appconstants, TrackingStatusConstant } from "shared/constants";
import { TrackingModel } from "shared/models";
import { trackingService } from "services";
import { DateTimeHelper } from "shared/helpers";
import ShipmentConfirm from "../../assets/Images/shipment-confirm-img.svg";
import ShipmentTransit from "../../assets/Images/transit-img.svg";
import ShipmentOutDelivery from "../../assets/Images/out-delivery-img.svg";
import ShipmentDelivered from "../../assets/Images/delivered-img.svg";
import backArrow from "../../assets/Images/back-arrow.svg";
import AwaitingImg from "../../assets/Images/awaiting-img.svg";
import errorImage from "../../assets/Images/error-img.svg";
import { Image } from "shared/models/tracking-model";

const initialData: TrackingModel = {
  tracking_number: "",
  shipment_tracking_number: "",
  created_date: "",
  company: null,
  address_from: null,
  address_to: null,
  transaction: "",
  eta: "",
  original_eta: "",
  tracking_status: {
    object_created: "",
    status: "",
    status_details: "",
    location: null,
    latitude: null,
    longitude: null,
    images: null,
    location_info: "",
  },
  tracking_history: [
    {
      object_created: "",
      status: "",
      status_details: "",
      location: null,
      latitude: null,
      longitude: null,
      images: null,
      location_info: "",
    },
  ],
  parcel_notes: [],
};

function TrackingDetails() {
  const [shipmentData, setShipmentData] = useState<TrackingModel>(initialData);
  const [shipmentStatus, setShipmentStatus] = useState(initialData.tracking_status)
  const location = useLocation();
  const [loading, isLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const searchParams = new URLSearchParams(location.search);
  let nonSanitizedTrackingNumber = searchParams.get("trackingNumber");
  if (nonSanitizedTrackingNumber == null) {
    nonSanitizedTrackingNumber =
      searchParams.get("ShipmentId") || searchParams.get("ShipmentID");
  }
  const trackingNumber = DOMPurify.sanitize(nonSanitizedTrackingNumber || "");
  const [isPrimary, setIsPrimary] = useState(false);
  const [isDanger, setIsDanger] = useState(false);
  const [isWarning, setIsWarning] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [isAwaitingAddressConfirmation, setIsAwaitingAddressConfirmation] = useState(false);
  //const [selectedImage, setSelectedImage] = useState<string | null>(null);
  const [selectedIndex, setSelectedIndex] = useState<number | null>(null);
  const [modalImages, setModalImages] = useState<Image[]>([]);

  function calculateAndDisplayRoute(
    directionsService: any,
    directionsDisplay: any,
    pointA: any,
    pointB: any,
    pointList: any
  ) {
    const waypts = [];
    for (let i = 0; i < pointList.length; i++) {
      if (pointList[i] != pointA && pointList[i] != pointB) {
        waypts.push({
          location: pointList[i],
          stopover: true,
        });
      }
    }
    directionsService.route(
      {
        origin: pointA,
        destination: pointB,
        waypoints: waypts,
        avoidTolls: true,
        avoidHighways: false,
        travelMode: window.google.maps.TravelMode.DRIVING,
      },
      function (response: any, status: any) {
        if (status == window.google.maps.DirectionsStatus.OK) {
          directionsDisplay.setDirections(response);
        }
        // else {
        //     window.alert(`${Appconstants.DIRECTION_REQUEST_FAILED}` + status);
        // }
      }
    );
  }
  const defaultProps = {
    center: { lat: 0, lng: 0 },
    zoom: 12,
  };
  const initMap = async () => {
    const { AdvancedMarkerElement, PinElement } =
      (await google.maps.importLibrary("marker")) as google.maps.MarkerLibrary;
    let bgcolor = $(".bg-color")
      .map(function () {
        return (this as HTMLInputElement).value;
      })
      .get();
    const pointList: any = [];
    const colorList: any = [];
    $(".lat-data").each((index) => {
      if (
        $(".lat-data")?.eq(index)?.val() == 0 ||
        $(".lng-data")?.eq(index)?.val() == 0 ||
        $(".lat-data")?.eq(index)?.val() == "undefined" ||
        $(".lng-data")?.eq(index)?.val() == "undefined" ||
        isNaN(Number($(".lat-data")?.eq(index)?.val())) ||
        isNaN(Number($(".lng-data")?.eq(index)?.val()))
      )
        return;

      pointList.push({
        lat: Number($(".lat-data")?.eq(index)?.val()),
        lng: Number($(".lng-data")?.eq(index)?.val()),
      });
      colorList.push(bgcolor[index]);
    });

    let pointA, pointB;
    if (pointList.length == 0) {
      pointA = "";
      pointB = "";
    } else {
      pointA = pointList[0];
      pointB = pointList[pointList.length - 1];
    }

    const mapElement = document.getElementById("map");
    let map: google.maps.Map | null = null;
    if (mapElement !== null) {
      map = new window.google.maps.Map(mapElement, {
        center: pointList[0],
        mapTypeId: window.google.maps.MapTypeId.ROADMAP,
        mapId: config.MAP_ID,
        zoom: 15,
        maxZoom: 12,
      });
    }

    for (let i = 0; i < pointList.length; i++) {
      const pinBackground = new PinElement({
        background: colorList[i],
        borderColor: "#000",
        glyphColor: "#000",
      });
      new AdvancedMarkerElement({
        map,
        position: pointList[i],
        content: pinBackground.element,
      });
    }

    // Instantiate a directions service.
    const directionsService = new window.google.maps.DirectionsService();
    const directionsDisplay = new window.google.maps.DirectionsRenderer({
      map: map ? map : undefined,
      suppressMarkers: true,
    });

    calculateAndDisplayRoute(
      directionsService,
      directionsDisplay,
      pointA,
      pointB===pointA ? {lat: 0, lng: 0} : pointB,
      pointList
    );
  };

  useEffect(() => {
    if (trackingNumber !== null) {
      isLoading(true);
      trackingService
        .getTracking(trackingNumber)
        .then((res) => {
          let trackingStatus = res?.tracking_status?.status;
          setShipmentStatus(res?.tracking_status);
          
          if (trackingStatus === TrackingStatusConstant.ADDRESS_CORRECTED) {
            for (let i = 1; i < res.tracking_history.length; i++) {
              
              if (res.tracking_history[i]?.status === TrackingStatusConstant.AWAITING_ADDRESS_CONFIRMATION ||
                res.tracking_history[i]?.status === TrackingStatusConstant.TRANSACTION_CREATED ||
                res.tracking_history[i]?.status === TrackingStatusConstant.ONFLEET_FAILED)
              {
                continue;
              }
              else
              {
                trackingStatus = TrackingStatusConstant.SCANNED_AT_HUB; //To make it in IN_TRANSIT
                setShipmentStatus(res.tracking_history[i]);
                break;
              }
            }
          }

          switch (trackingStatus) {
            case TrackingStatusConstant.TRANSACTION_CREATED:
            case TrackingStatusConstant.PARCEL_CREATED:
            case TrackingStatusConstant.SHIPMENT_CREATED:
            case TrackingStatusConstant.SHIPMENT_INTEGRATION:
            case TrackingStatusConstant.PARCEL_LABEL_CREATED:
            case TrackingStatusConstant.SHIPMENT_LABEL_CREATED:
            case TrackingStatusConstant.RECEIVED:
              setIsPrimary(true);
              break;

            case TrackingStatusConstant.PICKED_UP:
            case TrackingStatusConstant.PARCEL_CHANGED:
            case TrackingStatusConstant.SHIPMENT_CHANGED:
            case TrackingStatusConstant.LOADED_ON_DELIVERY_VEHICLE:
            case TrackingStatusConstant.IN_TRANSIT:

            case TrackingStatusConstant.OUT_FOR_DELIVERY:
            case TrackingStatusConstant.DELIVERY_FAILURE:
              setIsDanger(true);
              break;

            case TrackingStatusConstant.DELIVERED:
              setIsSuccess(true);
              break;

            case trackingStatus.includes(TrackingStatusConstant.SCANNED_AT_HUB) ? trackingStatus: "":
              setIsWarning(true);
              break;

            case TrackingStatusConstant.AWAITING_ADDRESS_CONFIRMATION:
              setIsAwaitingAddressConfirmation(true);
              break;
          }
        
          setShipmentData(res);
          isLoading(false);
          setIsError(false);
        })
        .catch((e) => {
          isLoading(false);
          setIsError(true);
        });
    }
  }, []);

  const seenDates = new Set();

  return trackingNumber ? (
    loading ? (
      <span className="loader"></span>
    ) : (
      <>
        <Header />
        <Container fluid className="shipment-track-content">
          <Row>
            <div className="shipment-detail-header">
              <Col md={10} xs={12} className="m-auto d-flex">
                <div className="shipment-detail-header-left">
                  <a href="/">
                    <img src={backArrow} alt="backArrow" />
                  </a>
                </div>
                <div className="shipment-detail-header-right">
                  <div className="shipment-detail-header-title">
                    PACKAGE TRACKING NUMBER
                  </div>
                  <div className="shipment-detail-header-number">
                    {trackingNumber}
                  </div>
                </div>
              </Col>
            </div>
          </Row>
          <Row className="justify-content-center">
            {/* <div className="w-100"> */}
            <Col md={10} xs={12} className="m-auto">
            {isError ? (
                  <div className="shipment-track-error error-container">
                    <img
                      src={errorImage}
                      alt="shipment-not-img"
                      className="tracking-image"
                    />

                    <div className="text-center teack-heading">
                      Oops... something went wrong
                    </div>

                    <div className="text-center tracking-text">
                      Please, give it a try later or call our{" "}
                      <a href="mailto:customer_support@bettertrucks.com">customer support</a> team!
                    </div>
                    <div className="track-another text-center">
                      <Link to="/">{Appconstants.TRACK_ANOTHER_SHIPMENT}</Link>
                    </div>
                </div>
              ) : shipmentData?.tracking_history?.length === 0 ? (
                <div className="shipment-track-error">
                  <img
                    src={notFoundImg}
                    alt="shipment-not-img"
                    className="tracking-image"
                  />
                  <div
                    className="text-center teack-heading"
                    key={shipmentData?.shipment_tracking_number}
                  >
                    Shipment can’t be found
                  </div>
                  <div className="text-center tracking-text">
                    We're sorry, but we couldn't find the tracking information
                    you're looking for.
                    <br />
                    Double-check your tracking number, give it a moment, or
                    contact support.
                  </div>
                  <div className="track-another text-center">
                    <Link to="/">{Appconstants.TRACK_ANOTHER_SHIPMENT}</Link>
                  </div>
                </div>
              ) : (
                <React.Fragment key={shipmentData?.shipment_tracking_number}>
                  <div className="track-head text-center">
                    {/* <img src={card} width="120" /> */}
                    {isPrimary && (
                      <div>
                        <img
                          src={ShipmentConfirm}
                          alt="shipment-confirmed-img"
                        />
                        <h6 className="track-delivery-datetime">
                          {shipmentStatus?.status_details}
                        </h6>
                      </div>
                    )}
                    {isWarning && (
                      <div>
                        <img src={ShipmentTransit} alt="shipment-transit-img" />
                        <h6 className="track-delivery-datetime">
                          {shipmentStatus?.status.toLocaleLowerCase() ===
                          TrackingStatusConstant.OUT_FOR_DELIVERY.toLocaleLowerCase()
                            ? Appconstants.IN_TRANSIT_TEXT
                            : shipmentStatus?.status_details}
                        </h6>
                      </div>
                    )}
                    {isDanger && (
                      <div>
                        <img
                          src={ShipmentOutDelivery}
                          alt="shipment-outdelivery-img"
                        />
                        <h6 className="track-delivery-datetime">
                          {shipmentStatus?.status.toLocaleLowerCase() ===
                          TrackingStatusConstant.OUT_FOR_DELIVERY.toLocaleLowerCase()
                            ? Appconstants.IN_TRANSIT_TEXT
                            : shipmentStatus?.status_details}
                        </h6>
                      </div>
                    )}
                    {isSuccess && (
                      <div>
                        <img
                          src={ShipmentDelivered}
                          alt="shipment-deliver-img"
                        />
                        <h6 className="track-delivery-datetime">
                          {shipmentStatus?.status_details}
                        </h6>
                      </div>
                    )}
                    {isAwaitingAddressConfirmation && (
                      <div>
                        <img
                          src={AwaitingImg}
                          alt="awaiting-img"
                        />
                        <h6 className="track-delivery-datetime track-delivery-awaiting-header">
                          Your package is awaiting address confirmation
                        </h6>
                        <p className="track-delivery-awaiting-text">
                          To ensure swift delivery, please validate your address
                          by contacting our support team via email at{" "}
                          <a href="mailto:customer_support@bettertrucks.com">
                              customer_support@bettertrucks.com
                          </a>{" "}or by communicating with{" "}
                          <a href="https://www.bettertrucks.com">
                              Better Trucks
                          </a>{" "}chat.
                        </p>
                      </div>
                    )}
                  </div>
                  <div className="tracking-status text-center">
                    {isPrimary && (
                            <div>
                              <img
                                src={trackingConfirmed}
                                alt="TrackingStatus"
                                className="tracking-status-img img-fluid tracking-img-desktop"
                              />
                              <img
                                src={trackingConfirmedMobile}
                                alt="TrackingStatus"
                                className="tracking-status-img img-fluid tracking-img-mobile"
                              />
                            </div>
                    )}
                    {(isWarning || isAwaitingAddressConfirmation) && (
                      <div>
                      <img
                        src={trackingTransit}
                        alt="TrackingStatus"
                        className="tracking-status-img img-fluid tracking-img-desktop"
                      />
                      <img
                        src={trackingTransitMobile}
                        alt="TrackingStatus"
                        className="tracking-status-img img-fluid tracking-img-mobile"
                      />
                    </div>
                    )}
                    {isDanger && (
                      <div>
                      <img
                        src={trackingOutForDelivery}
                        alt="TrackingStatus"
                        className="tracking-status-img img-fluid tracking-img-desktop"
                      />
                      <img
                        src={trackingOutForDeliveryMobile}
                        alt="TrackingStatus"
                        className="tracking-status-img img-fluid tracking-img-mobile"
                      />
                    </div>
                    )}
                    {isSuccess && (
                      <div>
                      <img
                        src={trackingDelivered}
                        alt="TrackingStatus"
                        className="tracking-status-img img-fluid tracking-img-desktop"
                      />
                      <img
                        src={trackingDeliveredMobile}
                        alt="TrackingStatus"
                        className="tracking-status-img img-fluid tracking-img-mobile"
                      />
                    </div>
                    )}
                  </div>
                  <div className="track-data track-data-table">
                    <Row>
                      <Col md={8} xs={12}>
                        <Table responsive>
                          <thead>
                            <tr>
                              <th scope="col">{Appconstants.DATE_HEADER}</th>
                              <th scope="col">{Appconstants.TIME_HEADER}</th>
                              <th scope="col">{Appconstants.STATUS_HEADER}</th>
                              <th scope="col">
                                {Appconstants.LOCATION_HEADER}
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            {shipmentData?.tracking_history?.map(
                              (value, key) => {
                                let primary: boolean = false;
                                let danger: boolean = false;
                                let warning: boolean = false;
                                let success: boolean = false;
                                let awaitingAddressConfirmation: boolean = false;
                                if (
                                  value?.status ==
                                    TrackingStatusConstant.PICKED_UP ||
                                  value?.status ==
                                    TrackingStatusConstant.PARCEL_CHANGED ||
                                  value?.status ==
                                    TrackingStatusConstant.SHIPMENT_CHANGED ||
                                  value?.status ==
                                    TrackingStatusConstant.SCANNED_AT_HUB ||
                                  value?.status ==
                                    TrackingStatusConstant.LOADED_ON_DELIVERY_VEHICLE ||
                                  value?.status ==
                                    TrackingStatusConstant.IN_TRANSIT ||
                                  value?.status ==
                                    TrackingStatusConstant.SCANNED_AT_HUB_COL ||
                                  value?.status ==
                                    TrackingStatusConstant.SCANNED_AT_HUB_NYC ||
                                  value?.status == 
                                    TrackingStatusConstant.ADDRESS_CORRECTED
                                )
                                  warning = true;
                                else if (
                                  value?.status ==
                                    TrackingStatusConstant.OUT_FOR_DELIVERY ||
                                  value?.status ==
                                    TrackingStatusConstant.ONFLEET_FAILED ||
                                  value?.status ==
                                    TrackingStatusConstant.DELIVERY_FAILURE
                                )
                                  danger = true;
                                else if (
                                  value?.status ==
                                  TrackingStatusConstant.DELIVERED
                                )
                                  success = true;
                                else if (
                                  value?.status ==
                                  TrackingStatusConstant.AWAITING_ADDRESS_CONFIRMATION
                                )
                                    awaitingAddressConfirmation = true;
                                else primary = true;

                                const date = DateTimeHelper.formatDate(value.object_created);
                                const isNewDate = !seenDates.has(date); // Check for new date
                                if (isNewDate && value?.status !==
                                  TrackingStatusConstant.ONFLEET_FAILED &&
                                value?.status !==
                                  TrackingStatusConstant.TRANSACTION_CREATED) {
                                  seenDates.add(date); // Add date to seenDates set
                                }

                                return (
                                  value?.status !==
                                    TrackingStatusConstant.ONFLEET_FAILED &&
                                  value?.status !==
                                    TrackingStatusConstant.TRANSACTION_CREATED && (
                                    <React.Fragment key={key}>
                                      <tr>
                                        <td className={`w-25${isNewDate ? '' : ' border-top-0'}`}>
                                          {isNewDate ? (<div>
                                            {DateTimeHelper.formatDate(
                                              value?.object_created
                                            )}
                                          </div>) : <></>}
                                        </td>
                                        <td className="w-16">
                                          <div>
                                            {DateTimeHelper.formatTime(
                                              value?.object_created
                                            )}
                                          </div>
                                        </td>
                                        <td className="w-25">
                                          {value?.status ===
                                          TrackingStatusConstant.SHIPMENT_CREATED ? (
                                            <>
                                              <input
                                                type="hidden"
                                                value={
                                                  value?.latitude == 0
                                                    ? shipmentData?.address_from
                                                        ?.latitude != 0
                                                      ? String(
                                                          shipmentData
                                                            ?.address_from
                                                            ?.latitude
                                                        )
                                                      : shipmentData?.company
                                                          ?.hub_address
                                                          ?.latitude
                                                      ? String(
                                                          shipmentData?.company
                                                            ?.hub_address
                                                            ?.latitude
                                                        )
                                                      : String(value.latitude)
                                                    : String(value.latitude)
                                                }
                                                className="lat-data"
                                              />
                                              <input
                                                type="hidden"
                                                value={
                                                  value?.longitude == 0
                                                    ? shipmentData?.address_from
                                                        ?.longitude != 0
                                                      ? String(
                                                          shipmentData
                                                            ?.address_from
                                                            ?.longitude
                                                        )
                                                      : shipmentData?.company
                                                          ?.hub_address
                                                          ?.longitute
                                                      ? String(
                                                          shipmentData?.company
                                                            ?.hub_address
                                                            ?.longitute
                                                        )
                                                      : String(value.longitude)
                                                    : String(value.longitude)
                                                }
                                                className="lng-data"
                                              />
                                            </>
                                          ) : (
                                            <>
                                              <input
                                                type="hidden"
                                                value={String(value.latitude)}
                                                className="lat-data"
                                              />
                                              <input
                                                type="hidden"
                                                value={String(value.longitude)}
                                                className="lng-data"
                                              />
                                            </>
                                          )}
                                          {primary && (
                                            <div className="track-badge-box">
                                              <div className="track-badge track-badge-primary">
                                                <input
                                                  type="hidden"
                                                  value="#5cb0f8"
                                                  className="bg-color"
                                                />
                                              </div>
                                              <div>
                                                {value?.status_details.toLocaleLowerCase() ===
                                                TrackingStatusConstant.RTS_RETURNED_TO_STATION.toLocaleLowerCase()
                                                  ? Appconstants.RETURNED_TO_STATION_TEXT.toLocaleLowerCase()
                                                  : value?.status_details}
                                              </div>
                                            </div>
                                          )}
                                          {warning && (
                                            <div className="track-badge-box">
                                              <div className="track-badge track-badge-warning">
                                                <input
                                                  type="hidden"
                                                  value="#095ec2"
                                                  className="bg-color"
                                                />
                                              </div>
                                              <div>{value?.status_details}</div>
                                            </div>
                                          )}
                                          {danger && (
                                            <div className="track-badge-box">
                                              <div className="track-badge track-badge-danger">
                                                <input
                                                  type="hidden"
                                                  value="#bd5cf8"
                                                  className="bg-color"
                                                />
                                              </div>
                                              <div>
                                                {value?.status ===
                                                TrackingStatusConstant.OUT_FOR_DELIVERY
                                                  ? Appconstants.IN_TRANSIT_TEXT
                                                  : value?.status_details}
                                              </div>
                                            </div>
                                          )}
                                          {success && (
                                            <div className="track-badge-box">
                                              <div className="track-badge track-badge-success">
                                                <input
                                                  type="hidden"
                                                  value="#00c189"
                                                  className="bg-color"
                                                />
                                              </div>
                                              <div>{value?.status_details}</div>
                                            </div>
                                          )}
                                          {awaitingAddressConfirmation && (
                                            <div className="track-badge-box">
                                              <div className="track-badge track-badge-waiting">
                                                <input
                                                  type="hidden"
                                                  value="#c9171f"
                                                  className="bg-color"
                                                />
                                              </div>
                                              <div>{value?.status_details}</div>
                                            </div>
                                          )}
                                        </td>
                                        <td className="w-25">
                                          <div>
                                            {value?.status_details.toLocaleLowerCase() ===
                                            Appconstants.SHIPMENT_CREATED_TEXT.toLocaleLowerCase()
                                              ? shipmentData?.address_from
                                                ? `${
                                                    shipmentData?.address_from
                                                      ?.city
                                                      ? shipmentData
                                                          ?.address_from?.city +
                                                        ", "
                                                      : ""
                                                  } ${
                                                    shipmentData?.address_from
                                                      ?.state
                                                      ? shipmentData
                                                          ?.address_from?.state
                                                      : ""
                                                  } ${
                                                    shipmentData?.address_from
                                                      ?.city &&
                                                    shipmentData?.address_from
                                                      ?.state &&
                                                    shipmentData.address_from
                                                      .country
                                                  }`
                                                : ""
                                              : value?.location
                                              ? `${
                                                  value?.location?.city
                                                    ? value?.location?.city +
                                                      ", "
                                                    : ""
                                                } ${
                                                  value?.location?.state
                                                    ? value?.location?.state
                                                    : ""
                                                } ${
                                                  value?.location?.city &&
                                                  value?.location?.state &&
                                                  value?.location?.country
                                                }` || ""
                                              : value?.location_info
                                              ? value.location_info + " US"
                                              : "--"}
                                          </div>
                                          {value?.images &&
                                            value?.images.filter(
                                              (i) =>
                                                i?.is_proof_of_delivery &&
                                                i?.is_safe_to_display
                                            ).length > 0 && (
                                            <tr>

                                              <div>
                                                {value?.images &&
                                                  value?.images
                                                    .filter(
                                                      (i) =>
                                                        i?.is_proof_of_delivery &&
                                                        i?.is_safe_to_display
                                                    )

                                                    .slice(0, 3) // Limit to the first 3 images
                                                    .map((image, index) => (
                                                      <td
                                                        className="text-center w-25 border-0"
                                                        key={index}
                                                      >

                                                        <div
                                                          className="shipped-img"
                                                          onClick={() => {
                                                            value?.images &&
                                                              setModalImages(
                                                                value?.images.filter(
                                                                  (i) =>
                                                                    i.is_proof_of_delivery &&
                                                                    i.is_safe_to_display
                                                                )
                                                              );
                                                            setSelectedIndex(
                                                              index
                                                            );
                                                          }}
                                                        >

                                                          <img
                                                            src={
                                                              image.imageUrl ??
                                                              ""
                                                            }
                                                            width="100px"
                                                            height="100px"
                                                            alt={`Image ${index + 1
                                                              }`}
                                                          />
                                                          </div>
                                                        </td>
                                                      ))}
                                                  {value?.images &&
                                                    value?.images.filter(
                                                      (i) =>
                                                        i?.is_proof_of_delivery &&
                                                        i?.is_safe_to_display
                                                    ).length > 3 && (
                                                      <td className="text-center w-25 border-0">
                                                        <div
                                                          className="shipped-more-img"
                                                          onClick={() => {
                                                            value?.images &&
                                                              setModalImages(
                                                                value?.images.filter(
                                                                  (i) =>
                                                                    i.is_proof_of_delivery &&
                                                                    i.is_safe_to_display
                                                                )
                                                              );
                                                            setSelectedIndex(3);
                                                          }}
                                                        >
                                                          {value?.images.filter(
                                                            (i) =>
                                                              i?.is_proof_of_delivery &&
                                                              i?.is_safe_to_display
                                                          ).length - 3}
                                                          {"+"}
                                                        </div>
                                                      </td>
                                                    )}
                                                </div>
                                              </tr>
                                            )}
                                        </td>
                                      </tr>
                                    </React.Fragment>
                                  )
                                );
                              }
                            )}
                          </tbody>
                        </Table>
                      </Col>
                      
                      <Col md={4} xs={12}>
                        <div id="map" className="map-view">
                          {
                            <GoogleMapReact
                              bootstrapURLKeys={{
                                key: config.MAP_API_KEY
                                  ? config.MAP_API_KEY
                                  : "",
                              }}
                              defaultCenter={defaultProps.center}
                              center={defaultProps.center}
                              defaultZoom={defaultProps.zoom}
                              yesIWantToUseGoogleMapApiInternals={true}
                              onGoogleApiLoaded={({ map, maps }) => initMap()}
                            />
                          }
                        </div>
                      </Col>
                    </Row>
                  </div>
                </React.Fragment>
              )}
            </Col>
            {/* </div> */}
          </Row>
          {modalImages.length > 0 && (
            <ImageGallery
              images={modalImages}
              toggle={() => {
                setModalImages([]);
                setSelectedIndex(null);
              }}
              activeIndex={selectedIndex ? selectedIndex : 0}
            />
          )}
        </Container>
        <Footer />
      </>
    )
  ) : (
    <Navigate to="/" replace={true} />
  );
}

export default TrackingDetails;
