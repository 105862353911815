import axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios';

enum StatusCode {
    Unauthorized = 401,
    Forbidden = 403,
    TooManyRequests = 429,
    InternalServerError = 500,
}

const headers: Readonly<Record<string, string | boolean>> = {
    Accept: "application/json",
    "Content-Type": "application/json; charset=utf-8",
}

class HttpService {
    private instance: AxiosInstance | null = null

    private get http(): AxiosInstance {
        return this.instance != null ? this.instance : this.initHttp()
    }

    initHttp() {
        const http = axios.create({
            baseURL: "/",
            headers,
        })

        http.interceptors.response.use(
            response => response,
            error => {
                const { response } = error
                return this.handleError(response)
            },
        )

        this.instance = http
        return http
    }

    request<T = any, R = AxiosResponse<T>>(config: AxiosRequestConfig): Promise<R> {
        return this.http.request(config)
    }
    get<T = any, R = AxiosResponse<T>>(url: string, config?: AxiosRequestConfig): Promise<R> {
        return this.http.get<T, R>(url, config)
    }

    private handleError(error: any) {
        const { status } = error

        switch (status) {
            case StatusCode.InternalServerError: {
                break
            }
            case StatusCode.Forbidden: {
                break
            }
            case StatusCode.Unauthorized: {
                break
            }
            case StatusCode.TooManyRequests: {
                break
            }
        }

        return Promise.reject(error)
    }
}

export const httpService = new HttpService()