import React from "react";
import { Container } from "react-bootstrap";
import { Appconstants } from "shared/constants";
const currentYear = new Date().getFullYear();
export const Footer = () => {
  return (
    <footer className="footer text-center">
      <Container>
        {Appconstants.RIGHTS_RESERVERD}
        {Appconstants.COPYRIGHT} &copy; {currentYear}{" "}
        <a href="https://www.bettertrucks.com/">
          {Appconstants.BETTER_TRUCKS_WEBTRACKING}
        </a>
        . {Appconstants.RIGHTS_RESERVERD}
        {Appconstants.VERSION_TEXT} {Appconstants.VERSION_NUMBER}
      </Container>
    </footer>
  );
};
