export class TrackingStatusConstant {
    static readonly TRANSACTION_CREATED:string = "TRANSACTION_CREATED";
    static readonly PARCEL_CREATED:string = "PARCEL_CREATED";
    static readonly SHIPMENT_CREATED:string = "SHIPMENT_CREATED";
    static readonly SHIPMENT_INTEGRATION:string = "SHIPMENT_INTEGRATION";
    static readonly PARCEL_LABEL_CREATED:string = "PARCEL_LABEL_CREATED";
    static readonly SHIPMENT_LABEL_CREATED:string = "SHIPMENT_LABEL_CREATED";
    static readonly RECEIVED:string = "RECEIVED";
    static readonly PICKED_UP:string = "PICKED_UP";
    static readonly PARCEL_CHANGED:string = "PARCEL_CHANGED";
    static readonly SHIPMENT_CHANGED:string = "SHIPMENT_CHANGED";
    static readonly SCANNED_AT_HUB:string = "SCANNED_AT_HUB";
    static readonly LOADED_ON_DELIVERY_VEHICLE:string = "LOADED_ON_DELIVERY_VEHICLE";
    static readonly IN_TRANSIT:string = "IN_TRANSIT";
    static readonly SCANNED_AT_HUB_COL:string = "SCANNED_AT_HUB_COL";
    static readonly SCANNED_AT_HUB_NYC:string = "SCANNED_AT_HUB_NYC";
    static readonly OUT_FOR_DELIVERY:string = "OUT_FOR_DELIVERY";
    static readonly DELIVERY_FAILURE:string = "DELIVERY_FAILURE";
    static readonly ONFLEET_FAILED:string = "ONFLEET_FAILED";
    static readonly DELIVERED:string = "DELIVERED";
    static readonly RTS_RETURNED_TO_STATION:string = "RTS_RETURNED_TO_STATION";
    static readonly AWAITING_ADDRESS_CONFIRMATION:string = "AWAITING_ADDRESS_CONFIRMATION";
    static readonly ADDRESS_CORRECTED:string = "ADDRESS_CORRECTED";
}