import React from "react";
import { Nav, Navbar, Container } from "react-bootstrap";
import logo from "../assets/Images/logo-white.png";
export const Header = () => {
  return (
    <header>
      <Navbar expand="sm">
        <Container fluid>
          <Navbar.Brand className="logo ">
            <a href="/">
              <img src={logo} alt="Logo" />
            </a>
          </Navbar.Brand>
        </Container>
      </Navbar>
    </header>
  );
};
