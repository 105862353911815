import { format, Locale } from "date-fns";

interface IFormatOptions {
    locale?: Locale;
    weekStartsOn?: 0 | 1 | 2 | 3 | 4 | 5 | 6;
    firstWeekContainsDate?: number;
    useAdditionalWeekYearTokens?: boolean;
    useAdditionalDayOfYearTokens?: boolean;
}

export class DateTimeHelper{
    static formatDate = (date: Date | number | string | null, formatter?: string, options?: IFormatOptions) => {
        if (!date) return null;

        let dt = date;

        if (typeof dt === "string") {
            if (!dt.includes("GMT") && !dt.includes("Z")) {
                dt = dt + "Z";
            }

            dt = new Date(dt);
        }

        if (typeof dt === "number") {
            dt = new Date(dt);
        }

        return format(dt, formatter || "MMMM d, yyyy", options);
    }
    
    static formatTime = (date: Date | number | string | null, formatter?: string, options?: IFormatOptions) => {
        if (!date) return null;

        let dt = date;

        if (typeof dt === "string") {
            if (!dt.includes("GMT") && !dt.includes("Z")) {
                dt = dt + "Z";
            }

            dt = new Date(dt);
        }

        if (typeof dt === "number") {
            dt = new Date(dt);
        }

        return format(dt, formatter || "hh:mm aa", options);
    }
}
