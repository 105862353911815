import React, { useState } from "react";
import { Button, Col, Form, Row, Container, InputGroup } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import DOMPurify from "dompurify";
import { Footer } from "components";
import "./index.scss";
import { Appconstants } from "shared/constants";
import logo from "../../assets/Images/logo-light.svg";
import searchImg from "../../assets/Images/search-img-blue.svg";

function TrackingPage() {
  const [trackingNumber, setTrackingNumber] = useState("");
  const navigate = useNavigate();
  const { handleSubmit } = useForm();

  const sanitizeInput = (input: string) => {
    return DOMPurify.sanitize(input); // Sanitize the input
  };

  const TrackShipment = () => {
    const sanitizedTrackingNumber = sanitizeInput(trackingNumber);

    if (!sanitizedTrackingNumber) {
      alert(Appconstants.TRACKING_NUMBER_FORM_FIELD);
      return false;
    }

    navigate(`/Tracking?trackingNumber=${sanitizedTrackingNumber}`);
  };
  return (
    <>
      {/* <Header /> */}
      <div className="tracking-main col-sm-12">
        <Form onSubmit={handleSubmit(TrackShipment)} className="tracking-form">
          <div id="search">
            <Container>
              <Row className="justify-content-md-center">
                <Col>
                  {/* <h5 className="text-center text-white">
                    {Appconstants.TRACKING_PACKAGE}
                  </h5> */}
                  <div className="logo-landing">
                    <img src={logo} alt="logo" />
                  </div>
                  <InputGroup size="lg">
                    {/* <Form.Group> */}
                    <InputGroup.Text>
                      <img src={searchImg} alt="logo" />
                    </InputGroup.Text>
                    <Form.Control
                      type="text"
                      id="ShipmentId"
                      name="ShipmentId"
                      value={trackingNumber}
                      placeholder={Appconstants.TRACKING_NUMBER_FORM_FIELD}
                      onChange={(e) => setTrackingNumber(e.target.value)}
                      size="lg"
                    />
                    <span className="glyphicon glyphicon-envelope form-control-feedback"></span>
                    <Form.Text className="help-block"></Form.Text>
                    <Form.Text className="error invalid-feedback help-block"></Form.Text>
                    {/* </Form.Group> */}
                    <Button
                      id="Track"
                      type="submit"
                      variant="primary"
                      className="btn-flat"
                    >
                      {Appconstants.TRACK_BUTTON}
                    </Button>
                  </InputGroup>
                </Col>
              </Row>
            </Container>
          </div>
        </Form>
        <Footer />
      </div>
    </>
  );
}

export default TrackingPage;
