import {
  TrackingPage,
  TrackingDetails
} from "./pages/index";
import { BrowserRouter, Route, Routes } from "react-router-dom";
const App = () => {
    
    return(
      <>
      <BrowserRouter >
      <Routes>
        <Route path="/" element={<TrackingPage />} />
        <Route path="/Tracking" element={<TrackingDetails />} />
      </Routes>
      </BrowserRouter>
      </>
      );
};

export default App;
